import BaseManager from '@/library/system/manager'
import Model from './model'
import BaseService from '../system/service'

class DealManager extends BaseManager {
    constructor () {
        super()
        this.model = new Model()
        this.loan_service = new BaseService('loan_service')
        this.vendor_service = new BaseService('vendor_service')
    }

    getDeals (pageData, callback) {
        let params = pageData;
        axios({
          url: this.loan_service.getServiceUrl("deals", "get")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
    }

    getDealId (dealName, callback) {
      let params = `?name=${dealName}`;
      axios({
        url: this.loan_service.getServiceUrl("getDealIdFromNameSearch", "post")+params,
        method: 'post',
        data: "",
      }).then(response => {
        callback(response.data)
      }).catch(error => {
        window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
        callback(error.response.data)
      })
  }

    getFacilities(pageData, callback) {
        let params = pageData;
        axios({
          url: this.loan_service.getServiceUrl("allFacilities", "post")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          console.log('error in getFacilities: ', error);
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
    }

    getMandates(pageData, callback) {
        let params = pageData;
        axios({
          url: this.loan_service.getServiceUrl("allMandates", "post")+params,
          method: 'get',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
    }

    getLoanData(callback) {
        this.loan_service.fetch('deals',callback,{messages:{error:''}})
    }

    getLoanTypes(callback) {
        this.loan_service.fetch('getLoanTypes',callback,{messages:{error:''}})
    }

    setDealName(data, callback) {
      this.loan_service.fetch("setDealName", callback, {
        method: "put",
        data: data,
        messages:{success:'Deal Updated Successfully', error:''}
      });
    }

    setLoanTypes(data, isBothSelectionUpdated, callback) {
        if (isBothSelectionUpdated === true) {
            messages = JSON.parse(JSON.stringify(messages))
            messages = messages.slice(0, 1);
        }
        this.loan_service.fetch("setLoanTypes", callback, {
            method: "put",
            data: data,
            messages:{success:'Deal Updated Successfully', error:''}
          });
    }

    setMandate(data, callback) {
        this.loan_service.fetch("setVender", callback, {
            method: "put",
            data: data,
            messages:{success:'Deal Updated Successfully', error:''}
          });
    }

    fetchVendorsFilter (vendor_id, callback) {
        this.loan_service.fetch('deals',callback,{'data':{'VendorId':vendor_id}, messages:{error:''}})
    }

    getVendors (callback) {
        this.vendor_service.fetch('vendors',callback,{messages:{error:''}})
    }

    filterDeals (dealFilterSettings, callback) {
      let pageNumber = dealFilterSettings.pageNumber
      let pageSize = dealFilterSettings.pageSize
        if(dealFilterSettings.status && dealFilterSettings.vendor_id){
            let params = ''
            if(dealFilterSettings.vendor_id == 0){
                params = `?IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
            } else {
                params = `?VendorId=${dealFilterSettings.vendor_id}&IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`; 
            }

            if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
              params += `&Name=${dealFilterSettings.Name}`
            }
            
            axios({
              url: this.loan_service.getServiceUrl("deals", "get")+params,
              method: 'post',
              data: "",
            }).then(response => {
              callback(response.data)
            }).catch(error => {
              window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
              callback(error.response.data)
            })

            // this.loan_service.fetch('deals',callback,{'data':{'VendorId':dealFilterSettings.vendor_id , 'IsActive' : dealFilterSettings.status}, messages:{error:''}})
        }else if(dealFilterSettings.status){
            let params = `?IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

            if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
              params += `&Name=${dealFilterSettings.Name}`
            }

            axios({
              url: this.loan_service.getServiceUrl("deals", "get")+params,
              method: 'post',
              data: "",
            }).then(response => {
              callback(response.data)
            }).catch(error => {
              window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
              callback(error.response.data)
            })

            // this.loan_service.fetch('deals',callback,{'data':{'IsActive':dealFilterSettings.status}, messages:{error:''}})
        }else if(dealFilterSettings.vendor_id){
            let params = ''
             if(dealFilterSettings.vendor_id == 0){
                params = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
            } else {
                params = `?VendorId=${dealFilterSettings.vendor_id}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
            }

            if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
              params += `&Name=${dealFilterSettings.Name}`
            }
            
            axios({
              url: this.loan_service.getServiceUrl("deals", "get")+params,
              method: 'post',
              data: "",
            }).then(response => {
              callback(response.data)
            }).catch(error => {
              window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
              callback(error.response.data)
            })

            // this.loan_service.fetch('deals',callback,{'data':{'VendorId':dealFilterSettings.vendor_id}, messages:{error:''}})
        }else{
            let params = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;

            if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
              params += `&Name=${dealFilterSettings.Name}`
            }

            axios({
              url: this.loan_service.getServiceUrl("deals", "get")+params,
              method: 'post',
              data: "",
            }).then(response => {
              callback(response.data)
            }).catch(error => {
              window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
              callback(error.response.data)
            })
            // this.loan_service.fetch('deals',callback, {messages:{error:''}})
        }
    }

    filterFacilities (dealFilterSettings, callback) {
      let pageNumber = dealFilterSettings.pageNumber
      let pageSize = dealFilterSettings.pageSize
      if(dealFilterSettings.status && dealFilterSettings.vendor_id){
        let params = ''
        if(dealFilterSettings.vendor_id == 0){
          params = `?IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
        } else {
          params = `?VendorId=${dealFilterSettings.vendor_id}&IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`; 
        }

        if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
          params += `&Name=${dealFilterSettings.Name}`
        }
            
        
        axios({
          url: this.loan_service.getServiceUrl("allFacilities", "post")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
      }else if(dealFilterSettings.status){
        let params = `?IsActive=${dealFilterSettings.status}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

        if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
          params += `&Name=${dealFilterSettings.Name}`
        }
        
        axios({
          url: this.loan_service.getServiceUrl("allFacilities", "post")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
      }else if(dealFilterSettings.vendor_id){
        let params = ''
          if(dealFilterSettings.vendor_id == 0){
            params = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
        } else {
            params = `?VendorId=${dealFilterSettings.vendor_id}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
        }

        if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
          params += `&Name=${dealFilterSettings.Name}`
        }

        axios({
          url: this.loan_service.getServiceUrl("allFacilities", "post")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
      }else{
        let params = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;

        if(dealFilterSettings.Name && dealFilterSettings.Name != null) {
          params += `&Name=${dealFilterSettings.Name}`
        }

        axios({
          url: this.loan_service.getServiceUrl("allFacilities", "post")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
      }
    }

    // filterFacilities (dealFilterSettings, callback) {
    //     if(dealFilterSettings.status && dealFilterSettings.vendor_id){
    //         this.loan_service.fetch('allFacilities',callback,
    //             {
    //                 'data':{'VendorId':dealFilterSettings.vendor_id , 'IsActive' : dealFilterSettings.status}, 
    //                 messages:{error:''}
    //             }
    //         )
    //     }else if(dealFilterSettings.status){
    //         this.loan_service.fetch('allFacilities',callback,
    //             {
    //                 'data':{'IsActive':dealFilterSettings.status},
    //                 messages:{error:''}
    //             }
    //         )
    //     }else if(dealFilterSettings.vendor_id){
    //         this.loan_service.fetch('allFacilities',callback,
    //             {
    //                 'data':{'VendorId':dealFilterSettings.vendor_id}, 
    //                 messages:{error:''}
    //             }
    //         )
    //     }else{
    //         this.loan_service.fetch('deals',callback, 
    //             {
    //                 messages:{error:''}
    //             }
    //         )
    //     }
    // }

    getDealData (dealId, callback) {
        // this.loan_service.fetch('deals',callback,{'data':{'DealId':dealId}, messages:{error:''}})
        let params = `?DealId=${dealId}&PageNumber=1&PageSize=1`;
        axios({
          url: this.loan_service.getServiceUrl("deals", "get")+params,
          method: 'post',
          data: "",
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
          callback(error.response.data)
        })
    }

    getFacilitiesByDealId(dealId, callback) {
        this.loan_service.fetch('facilitiesByDealId', callback, {
            data: { dealId: dealId }, 
            messages: {error: ''}
        });
    }

    updateDealActivationOption(data, callback) {
        let successMessage = '';
        if(data.isActive == true)
          successMessage = 'Deal Activated Successfully';
        else
          successMessage = 'Deal Deactivated Successfully';

        this.loan_service.fetch("setActivation", callback, 
        {
          method: "put", 
          data: data, 
          messages: {success: `${successMessage}`, error:''}
        });
    }
}

export default new DealManager()

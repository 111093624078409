import Vue from 'vue'
import Vuex from 'vuex'
import tasks from './modules/tasks/taskViewer'
import workbench from './modules/workbench/workbench'
import refObjRoles from './modules/refObjRoles/refObjRoles'
import loan from './modules/loan/deal.js'
import taskDefault from './modules/tasks/taskDefault.js'
import referenceAssigment from './modules/tasks/referenceAssigment.js'
import templateManager from './modules/tasks/templateManager.js'
import taskRoles from './modules/tasks/taskRoles.js'
import roles from './modules/roles/roles.js'
import taskTags from './modules/tasks/taskTags.js'
import taskWorkflow from './modules/tasks/taskWorkflow.js'
import notifications from './modules/notifications/notifications.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        tasks,
        workbench,
        refObjRoles,
        loan,
        taskDefault,
        referenceAssigment,
        templateManager,
        taskRoles,
        roles,
        taskTags,
        taskWorkflow,
        notifications
    }
})
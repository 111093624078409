export const usersRoutes = [
    {
        path:"/user", 
        name:'UserManager', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path:'/user/users',
                name: 'UserManager.User',
                component: () => import('./User.vue'),
                meta: {requiresAuth: true, resource: ["TeamManagement", "SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/group',
                name: 'UserManager.Group',
                component: () => import('./UserGroup.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/roles',
                name: 'UserManager.Roles',
                component: () => import('./Roles.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            // {
            //     path:'/user/userRoles',
            //     name: 'UserManager.UsersRoles',
            //     component: () => import('./UserRoles.vue'),
            //     meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly"]},
            // },
            {
                path:'/user/userResource',
                name: 'UserManager.UserResources',
                component: () => import('./UserResource.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/permission',
                name: 'UserManager.UserPermission',
                component: () => import('./UserPermission.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/userSystemPermission',
                name: 'UserManager.UserSystemPermission',
                component: () => import('./UserSystemPermission.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/userMandate',
                name: 'UserManager.UserMandate',
                component: () => import('./UserMandate.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/userSystemPermissionGroup',
                name: 'UserManager.UserSystemPermissionGroup',
                component: () => import('./UserSystemPermissionGroup.vue'),
                meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            },
            {
                path:'/user/switchUser',
                name: 'UserManager.SwitchUser',
                component: () => import('./SwitchUser.vue'),
                meta: {requiresAuth: true, resource: ["TeamManagement", "All"], permission: ["ReadWrite", "Approver"]}
            },
            {
                path:'/user/copyUserGroups',
                name: 'UserManager.CopyUserGroups',
                component: () => import('./CopyUserGroups.vue'),
                meta: {requiresAuth: true, resource: ["TeamManagement", "All"], permission: ["ReadWrite", "Approver"]},
            },
            // {
            //     path:'/user/referenceAssignments',
            //     name: 'UserManager.ReferenceAssignments',
            //     component: () => import('./ReferenceAssignments.vue'),
            //     meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly"]},
            // },
            // {
            //     path:'taskRoleManager',
            //     name: 'UserManager.TaskRoleManager',
            //     component: () => import('./TaskRoles.vue'),
            //     meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            // },
            // {
            //     path:'refObjectRolesManager',
            //     name: 'UserManager.RefObjectRolesManager',
            //     component: () => import('./RefObjectRoles.vue'),
            //     meta: {requiresAuth: true, resource: ["SecurityManagement", "All"], permission: ["ReadWrite", "ReadOnly", "Approver"]},
            // },
        ]
    },
]
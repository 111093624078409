import BaseModel from '@/library/system/model'
import BaseService from '../system/service'
class Model extends BaseModel {
    constructor () {
        super()
        this.loan_service = new BaseService('loan_service')
        this.vendor_service = new BaseService('vendor_service')
    }

    getLoanData(callback) {
       this.loan_service.fetch('deals',callback)
    }

    fetch_vendors (vendor_id, callback) {
        console.log(vendor_id)
       this.loan_service.fetch('deals',callback,{'data':{'VendorId':vendor_id}})
        /*
        let url = window.app_settings.services.loan_service.base_url+window.app_settings.services.loan_service.endpoints.deals
        let urlParam = `?VendorId=${vendor_id}`
        url = `${url}${urlParam}`
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            console.log(error);
            callback(null, error)
        })
        */
    }

    filterDeals (dealFilterSettings, callback) {
        let urlParam
        let url = window.app_settings.services.loan_service.base_url+window.app_settings.services.loan_service.endpoints.deals
        console.log(dealFilterSettings)
        if(dealFilterSettings.status && dealFilterSettings.vendor_id){
            urlParam = `?IsActive=${dealFilterSettings.status}&VendorId=${dealFilterSettings.vendor_id}`
        }else{
            urlParam = `?IsActive=${dealFilterSettings.status}`
        }
        url = `${url}${urlParam}`
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
}

export default Model
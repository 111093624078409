<template>
  <div id="notification-outer" class=" dropdown-menu-notification dropdown-menu-right dropdown-notification-menu">
    <div class="dropdown-header">
      <h6>Notifications</h6>
      <a
        href="#"
        class="text-primary mark-read"
        v-if="notificationList.length > 0"
        @click="clearAllNotifications"
        >
        Clear all notifications
      </a>
    </div>
    <div class="dropdown-body">
      <ul class="dropdown-list">
        <li v-if="notificationList.length === 0" class="empty-notification">
          <div class="mail-content no-notification">
            <i aria-hidden="true" class="fa fa-chalkboard"></i>
            <p class="text-wrap">You have no notifications</p>
          </div>
        </li>
        <li v-for="notification of notificationList"
          :key="notification.id"
          @click="notificationClicked(notification.id)"
          >
          <div class="btn-wrapper">
            <a href="#" class="btn btn-circle btn-success">
              <span class="icon-wrap">
                <i aria-hidden="true" class="fa fa-chalkboard"></i>
              </span>
            </a>
          </div>
          <div class="mail-content">
            <p>{{ notification.title }}</p>
            <p>({{ parseTimestamp(notification.updatedTimestamp) }})</p>
          </div>
          <div class="mail-content">
            <span class="text-wrap">{{ notification.body }}</span>
          </div>
          <div class="dropdown-footer">
            <a href="#" class="text-primary mark-read" @click.stop="clearNotification(notification.id)">
              Dismiss
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
export default {
  name: 'notificationList',
  props: {
    notificationList: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  methods: {
    parseTimestamp(timestamp) {
      let date = moment(timestamp);
      const dateComponent = date.utc().format('YYYY-MM-DD');
      const timeComponent = date.utc().format('HH:mm');
      return dateComponent + ' ' + timeComponent;
    },
    notificationClicked(id) {
      this.$emit('notificationItemClicked', id);
    },
    clearAllNotifications() {
      this.$emit('clearAllNotifications');
    },
    clearNotification(id) {
      this.$emit('clearNotification', id);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
const state = {
  dataGrid: {
    colFilter:null,
    colSort:[],
    statusBtn: {

    },
    referenceTypeId: null,
    referenceKey: null,
    referenceById: null,
    refUserId: null,
    taskType: null,
    gridRowIndex: 0,
    checkFilter: {},
    status: [], 
    loanType: [],
    mandate: [],
    isActive: [],
    tasksId: [],
    selectAll: null,
    selectedTaskId: null,
    pageData: [],
    pageNumber: null,
    pageSize: null,
    workflowId: null,
    workflowName: null,
    accessFromWorkflow: null,
    workflowNameFromRoute: null
  },
  deals: {
    colId: null
  }
};

const getters = {
  getColFilter: state => {
    return state.dataGrid.colFilter;
  },
  getWorkflowId: state => {
    return state.dataGrid.workflowId;
  },
  getWorkflowName: state => {
    return state.dataGrid.workflowName;
  },
  getAccessFromWorkflow: state => {
    return state.dataGrid.accessFromWorkflow;
  },
  getWorkflowNameFromRoute: state => {
    return state.dataGrid.workflowNameFromRoute;
  },
  getColSort: state => {
    return state.dataGrid.colSort;
  },
  getReferenceTypeStoreId: state => {
    return state.dataGrid.referenceTypeId;
  },
  getReferenceKey: state => {
    return state.dataGrid.referenceKey;
  },
  getReferenceById: state => {
    return state.dataGrid.referenceById;
  },
  getRefUserId: state => {
    return state.dataGrid.refUserId;
  },
  getDealColId: state => {
    return state.deals.colId;
  },
  getTaskType: state => {
    return state.dataGrid.taskType;
  },
  getGridRowIndex: state => {
    return state.dataGrid.gridRowIndex;
  },
  getCheckFilter: state => {
    return state.dataGrid.checkFilter;
  },
  getStatus: state => {
    return state.dataGrid.status;
  },
  getLoanType: state => {
    return state.dataGrid.loanType;
  },
  getMandate: state => {
    return state.dataGrid.mandate;
  },
  getIsActive: state => {
    return state.dataGrid.isActive;
  },
  getTasksId: state => {
    return state.dataGrid.tasksId;
  },
  getSelectAll: state => {
    return state.dataGrid.selectAll;
  },
  getSelectedTaskId: state => {
    return state.dataGrid.selectedTaskId;
  },
  getPageData: state => {
    return state.dataGrid.pageData;
  },
  getPageNumber: state => {
    return state.dataGrid.pageNumber;
  },
  getPageSize: state => {
    return state.dataGrid.pageSize;
  }
};

const mutations = {
  setColFilter: (state, payload) => {
    state.dataGrid.colFilter = payload;
  },
  setWorkflowId: (state, payload) => {
    state.dataGrid.workflowId = payload;
  },
  setWorkflowName: (state, payload) => {
    state.dataGrid.workflowName = payload;
  },
  setAccessFromWorkflow: (state, payload) => {
    state.dataGrid.accessFromWorkflow = payload;
  },
  setWorkflowNameFromRoute: (state, payload) => {
    state.dataGrid.workflowNameFromRoute = payload;
  },
  setColSort: (state, payload) => {
    state.dataGrid.colSort = payload;
  },
  setReferenceTypeStoreId: (state, payload) => {
    state.dataGrid.referenceTypeId = payload;
  },
  setReferenceKey: (state, payload) => {
    state.dataGrid.referenceKey = payload;
  },
  setReferenceById: (state, payload) => {
    state.dataGrid.referenceById = payload;
  },
  setRefUserId: (state, payload) => {
    state.dataGrid.refUserId = payload;
  },
  setDealColId: (state, payload) => {
    state.deals.colId = payload;
  },
  setTaskType: (state, payload) => {
    state.dataGrid.taskType = payload;
  },
  setGridRowIndex: (state, payload) => {
    state.dataGrid.gridRowIndex = payload;
  },
  setCheckFilter: (state, payload) => {
    state.dataGrid.checkFilter = payload;
  },
  setStatus: (state, payload) => {
    state.dataGrid.status = payload;
  },
  setLoanType: (state, payload) => {
    state.dataGrid.loanType = payload;
  },
  setMandate: (state, payload) => {
    state.dataGrid.mandate = payload;
  },
  setIsActive: (state, payload) => {
    state.dataGrid.isActive = payload;
  },
  setTasksId: (state, payload) => {
    state.dataGrid.tasksId = payload;
  },
  setSelectAll: (state, payload) => {
    state.dataGrid.selectAll = payload;
  },
  setSelectedTaskId: (state, payload) => {
    state.dataGrid.selectedTaskId = payload;
  },
  setPageData: (state, payload) => {
    state.dataGrid.pageData = payload;
  },
  setPageNumber: (state, payload) => {
    state.dataGrid.pageNumber = payload;
  },
  setPageSize: (state, payload) => {
    state.dataGrid.pageSize = payload;
  }
};

const actions = {
    setColFilter: ({ commit }, payload) => {
        commit('setColFilter', payload);
    },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
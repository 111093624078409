const state = {
    notificationList: []
};
const getters = {
    getNotifications: state => {
        return state.notificationList;
    }
};

const mutations = {
    setNotifications: (state, payload) => {
      state.notificationList.push({...payload});
    }
};

const actions = {
    setNotifications: ({ commit }, payload) => {
        commit('setNotifications', payload);
    },
    clearNotification: (state, payload) => {
      state.notificationList.splice()
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
const state = {
    dataGrid: {
        colFilter: null,
        colSort: [],
        referenceType: null,
        subReference: null
    },
};

const getters = {
    getColFilter: state => {
        return state.dataGrid.colFilter;
    },
    getColSort: state => {
        return state.dataGrid.colSort;
    },
    getReferenceType: state => {
        return state.dataGrid.referenceType;
    },
    getSubReference: state => {
        return state.dataGrid.subReference;
    },
};

const mutations = {
    setColFilter: (state, payload) => {
        state.dataGrid.colFilter = payload;
    },
    setColSort: (state, payload) => {
        state.dataGrid.colSort = payload;
    },
    setReferenceType: (state, payload) => {
        state.dataGrid.referenceType = payload;
    },
    setSubReference: (state, payload) => {
        state.dataGrid.subReference = payload;
    },
};

const actions = {
    setColFilter: ({ commit }, payload) => {
        commit('setColFilter', payload);
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import Vue from 'vue';

Vue.filter('dateFormate', value => {
    let STORAGE_KEY_LOCALE = 'locale-setting';
    let localeSettingSelected = window.getStorage(STORAGE_KEY_LOCALE);
    if (value == '' || value == null || value == 'undefined') {
        return null
    } else {
        var dateValue = value
        var newDateValue = new Date(dateValue)
        var options = { year: 'numeric', month: 'short', day: '2-digit' };

        var formattedDate;
        if (window.getStorage("locale-setting") && window.getStorage("locale-setting") != 'null') {
            formattedDate = newDateValue.toLocaleDateString(localeSettingSelected, options)
        } else {
            formattedDate = newDateValue.toLocaleDateString('en-GB', options)
        }
        return formattedDate
    }
});
Vue.filter('dateFormateTime', value => {
    let STORAGE_KEY_LOCALE = 'locale-setting';
    let localeSettingSelected = window.getStorage(STORAGE_KEY_LOCALE);
    if (value == '' || value == null || value == 'undefined') {
        return null
    } else {
        var dateValue = value
        var newDateValue = new Date(dateValue)
        var options = { year: 'numeric', month: 'short', day: '2-digit' , hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit'};

        var formattedDate;
        if (window.getStorage("locale-setting") && window.getStorage("locale-setting") != 'null') {
            formattedDate = newDateValue.toLocaleDateString(localeSettingSelected, options)
        } else {
            formattedDate = newDateValue.toLocaleDateString('en-GB', options)
        }
        return formattedDate
    }
});
Vue.filter('to-uppercase', value => {
    return value.toUpperCase();
});

Vue.filter('to-lowercase', value => {
    return value.toLowerCase();
});
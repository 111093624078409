const state = {
  dataGrid: {
    colFilter: null,
    colSort: [],
    pageNumber: null,
    pageSize: null,
    isHeaderFilterApplied: false,
  },
};

const getters = {
  getColFilter: state => {
    return state.dataGrid.colFilter;
  },
  getColSort: state => {
    return state.dataGrid.colSort;
  },
  getPageNumber: state => {
    return state.dataGrid.pageNumber;
  },
  getPageSize: state => {
    return state.dataGrid.pageSize;
  },
  getIsHeaderFilterApplied: state => {
    return state.dataGrid.isHeaderFilterApplied
  }
};

const mutations = {
  setColFilter: (state, payload) => {
    state.dataGrid.colFilter = payload;
  },
  setColSort: (state, payload) => {
    state.dataGrid.colSort = payload;
  },
  setPageNumber: (state, payload) => {
    state.dataGrid.pageNumber = payload;
  },
  setPageSize: (state, payload) => {
    state.dataGrid.pageSize = payload;
  },
  setIsHeaderFilterApplied: (state, payload) => {
    state.dataGrid.isHeaderFilterApplied = payload
  }
};

const actions = {
  setColFilter: ({ commit }, payload) => {
    commit('setColFilter', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
import Vue from 'vue'
import { ValidationProvider} from 'vee-validate';
import { ValidationObserver} from 'vee-validate';
import { extend } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { required , numeric , max, max_value, confirmed } from 'vee-validate/dist/rules';
extend('max' , max)

extend('max', {
  ...max,
  message: 'The field may not be greater than 5 characters'
});

extend('maxDigit', {
  ...max,
  message (field, values) {
    var digit_lengths = values._value_.toString().length
    if(digit_lengths => values){
      return `The field may not be greater than ${values.length} digits` 
    }
  }
});

extend('maxDigit' , max)


extend('max_value', {
  ...max_value,
  message (field, values) {
    var digit_lengths = values._value_.toString().length
    if(digit_lengths => values){
      return `The field may not be greater than ${values.max}` 
    }
  }
});
extend('max_value' , max_value)

// extend('required', {
//   ...required,
//   message: 'This field is required'
// });

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: 'This field is required'
});

extend('roleRequired', {
  params: ['target'],
  validate(value, {target}) {
    return target !== '-- Select Role --' && target !== '' && target !== null;
  },
  message: 'You must specify a user to assign to the selected role'
});
extend('numeric', {
  ...numeric,
  message: 'This field should be in number format'
});

extend('beforeDate', {
    params: ['target'],
    validate(value, { target }) {
      if(target){
        var startDate = Date.parse(target)
        var endDate = Date.parse(value)
        return endDate <= startDate
      }else{
        return true
      }
    },
    message: 'Reference Date should be earlier than Until Date'
  });
  
  
  extend('afterDate', {
    params: ['target'],
    validate(value, { target }) {
      if(target){
      var startDate = Date.parse(target)
      var endDate = Date.parse(value)
      return endDate >= startDate;
      }
    else{
      return true
    }
    },
    message: 'Until Date should not be before Reference Date'
  });


  extend('afterSelected', {
    params: ['until_date' , 'no_of_cycles'],
    validate(value, { until_date ,no_of_cycles}) {
      if(!until_date && !no_of_cycles){
        return false
      }
      return true
    },
    message: 'Either no. of cycles or Until Date is required'
  });


  extend('offsetLimit', {
    params: ['offset_value'],
    validate(value, {offset_value}) {
      if(value > 31 || value < -31){
        return false
      }
      return true
    },
    message: 'Value must be between -31 to 31'
  });

  extend('offsetLimitmaxsnooze', {
    params: ['offset_value'],
    validate(value, {offset_value}) {
      if(value > 31){
        return false
      }
      return true
    },
    message: 'Value must be between 0 to 31'
  });
  
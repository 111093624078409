export const assetsRoutes = [
    {
        path:"/assets",
        name: 'Asset', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "Asset.Overview"
        },
        children: [
            {
                path:'overview',
                name: 'Asset.Overview',
                component: () => import('./Overview.vue'),
            },
            {
                path:'convenant_calculation',
                name: 'Asset.ConvenantCalculation',
                component: () => import('./CovenantCalculation.vue'),
            },
            {
                path:'report',
                name: 'Asset.Report',
                component: () => import('./Report.vue'),
            }
        ]
    },
]
export const notFoundRoutes = [
    {
        path:"*",
        name: 'NotFound', 
        component: () => import('./Index.vue'),
        // meta: { resource: "NotFound", permission: ['ReadWrite', 'ReadOnly']},
        redirect: {
            name: "NotFound.NotFound"
        },
        children: [
            {
                path:'',
                name: 'NotFound.NotFound',
                // meta: { resource: "NotFound", permission: ['ReadWrite', 'ReadOnly']},
                component: () => import('./NotFound.vue'),
            }
        ]
    },
]
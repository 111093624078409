export const reportsRoutes = [
    {
        path:"/reports",
        name: 'Reports', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true, resource: "Reports", permission: ['ReadWrite', 'ReadOnly', 'Approver']},
        redirect: {
            name: "Reports.Reports"
        },
        children: [
            {
                path:'',
                name: 'Reports.Reports',
                meta: {requiresAuth: true, resource: ["Reports", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
                component: () => import('./Reports.vue'),
            }
        ]
    },
]
export const marketDataRoutes = [
    {
        path:"/market_data",
        name: 'MarketData', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "MarketData.InterestRates"
        },
        children: [
            {
                path:'interest_rate',
                name: 'MarketData.InterestRates',
                component: () => import('./InterestRates.vue'),
            }
        ]
    }, 
]
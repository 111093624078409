import BaseModel from '@/library/system/model'

class Model extends BaseModel {
    constructor () {
        super()
    }

    get_vendors (callback) {
        let url = window.app_settings.users.list_users.base_url+window.app_settings.users.list_users.endpoints.users
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }

    get_users (callback) {
        let url = window.app_settings.services.user_services.base_url+window.app_settings.services.user_services.endpoints.allUsers.get
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }

    userLogin (logindata, callback) {
        let url = window.app_settings.services.user_services.base_url+window.app_settings.services.user_services.endpoints.userLogin.post
        axios.post(url, logindata).then(response => {
            callback(response.data)
            // const token = response.data.token
            // console.log(token)
        }).catch(error => {
            console.log(error)
            callback(error.response.data)
        })
    }
}

export default Model
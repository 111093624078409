export const profileRoutes = [
    {
        path:"/profile",
        name:'Profile',
        meta: {requiresAuth: true, allowForcePass: true},
        component: () => import('./Profile.vue'),
        props: true
    },
    {
        path:"/profile/user-preference",
        name:'userPreference',
        meta: {requiresAuth: true, resource: ['Dashboard', 'All'], permission: ['ReadWrite', 'ReadOnly']},
        component: () => import('./Profile.vue'),
    },
    {
        path:"/profile/user-preference/local-settings",
        name:'localeSettings',
        meta: {requiresAuth: true, resource: ['Dashboard', 'All'], permission: ['ReadWrite', 'ReadOnly']},
        component: () => import('./Profile.vue'),
    },
    {
        path:"/profile/user-preference/theme-selector",
        name:'themeSelector',
        meta: {requiresAuth: true, resource: ['Dashboard', 'All'], permission: ['ReadWrite', 'ReadOnly']},
        component: () => import('./Profile.vue'),
    },
    // {
    //     path:'/dashboard',
    //     name: 'dashboard',
    //     component: () => import('../Dashboard.vue'),
    //     meta: {requiresAuth: true, resource: ['Dashboard', 'All'], permission: ['ReadWrite', 'ReadOnly']}
    // },
]
const state = {
    dataGrid: {
        colFilter:null,
        colSort:[],
        allOptionsSelected: false
    }
};

const getters = {
    getColFilter: state => {
       return state.dataGrid.colFilter;
    },
    getColSort: state => {
       return state.dataGrid.colSort;
    },
    getAllOptionsSelected: state => {
       return state.dataGrid.allOptionsSelected;
    }
};

const mutations = {
    setColFilter: (state, payload) => {
        state.dataGrid.colFilter = payload;
    },
    setColSort: (state, payload) => {
        state.dataGrid.colSort = payload;
    },
    setAllOptionsSelected: (state, payload) => {
        state.dataGrid.allOptionsSelected = payload;
    }
};

const actions = {
    setColFilter: ({ commit }, payload) => {
        commit('setColFilter', payload);
    },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
<template>
  <div id="app" class="page-body" style="height: 980px">
    <ApiMessage></ApiMessage>
    <header-main v-if="!hideComponent" v-on:logout="logout"></header-main>
    <TheNavbar v-if="!hideComponent" :tree-data="treeData()"></TheNavbar>
    <router-view
      @login="login"
      @logout="logout"
      @termsPolicies="termsPolicies"
      @setLogin="setLogin"
    />
    <TheFooter></TheFooter>
  </div>
</template>
<script>
import TheNavbar from "@/layout/TheNavbar.vue";
import HeaderMain from "@/layout/TheHeader.vue";
import ApiMessage from "@/components/ApiMessage.vue";
import TheFooter from "@/layout/TheFooter.vue";
import SecurityService from "./services/SecurityService";
export default {
  name: "app",
  components: {
    TheNavbar,
    HeaderMain,
    ApiMessage,
    TheFooter,
  },
  data() {
    return {
      userFound: false,
      signedIn: false,
      themeColorPrefix: "themeColor",
      hideComponent: true,
      routerErr: "",
      mockAccount: {
        username: "admin",
        password: "password",
      },
      waitApi: false,
      tree: [
        {
          title: "Task List",
          link: "/taskManager/Tasks",
          icon: "task",
          resource: ["TaskManagement", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
          show: true,
        },
        {
          title: "Workflow",
          link: "/taskManager/Workflow",
          icon: "multiple_stop",
          resource: ["TaskManagement", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
          show: true,
        },
        {
          title: "Configuration",
          link: "/configuration",
          icon: "build",
          resource: ["TaskManagement", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
          show: true,
          children: [
            {
              title: "Configure Tasks",
              link: "/taskManager/TaskConfiguration",
              resource: ["TaskManagement", "All"],
              permission: ["ReadWrite"],
            },
            {
              title: "Generate Tasks",
              link: "/taskManager/Generator",
              resource: ["TaskManagement", "All"],
              permission: ["ReadWrite"],
            },
            {
              title: "Manage Templates",
              link: "/taskManager/TemplateManager",
              resource: ["TaskManagement", "All"],
              permission: ["ReadWrite", "Approver"],
            },
            {
              title: "Holiday Calendars",
              link: "/staticData/holidayCalendar",
              resource: ["BusinessCalendarManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
          ],
        },
        {
          title: "User Admin",
          link: "/user",
          icon: "supervisor_account",
          show: true,
          resource: ["SecurityManagement", "TeamManagement", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
          children: [
            {
              title: "Users",
              link: "/user/users",
              resource: ["TeamManagement", "SecurityManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Group",
              link: "/user/group",
              resource: ["SecurityManagement", "TeamManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Roles",
              link: "/user/roles",
              resource: ["SecurityManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Resource",
              link: "/user/userResource",
              resource: ["SecurityManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Mandate",
              link: "/user/userMandate",
              resource: ["SecurityManagement", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Switch User",
              link: "/user/SwitchUser",
              resource: ["TeamManagement", "All"],
              permission: ["ReadWrite", "Approver"],
            },
            {
              title: "Copy User Groups",
              link: "/user/copyUserGroups",
              resource: ["TeamManagement", "All"],
              permission: ["ReadWrite", "Approver"],
            },
          ],
        },
        {
          title: "User Preferences",
          link: "/profile/user-preference",
          icon: "settings",
          resource: ["Dashboard", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
          show: true,
          children: [
            {
              title: "Locale Settings",
              link: "/profile/user-preference/local-settings",
              resource: ["Dashboard", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
            {
              title: "Theme Selector",
              link: "/profile/user-preference/theme-selector",
              resource: ["Dashboard", "All"],
              permission: ["ReadWrite", "ReadOnly", "Approver"],
            },
          ],
        },
        {
          title: "Reports",
          link: "/reports",
          icon: "description",
          show: true,
          resource: ["Reports", "All"],
          permission: ["ReadWrite", "ReadOnly", "Approver"],
        },
      ],
    };
  },
  created() {
    this.setLayout();
  },
  mounted() {
    if (
      window.getStorage("user-token") != null ||
      window.getStorage("ch_user-token") != null
    ) {
      this.userFound = true;
      this.hideComponent = false;
    }
    this.setPreference();
    this.getThemeColor();
    this.$root.$on("setLogin", () => {
      this.setLogin(true);
    });
  },
  watch: {},
  methods: {
    treeData() {
      var permissionData = window.getStorage("aclData");
      if (permissionData == null) return;
      permissionData = JSON.parse(permissionData);
      var newTree = [];
      var menuToBeShown = [];
      //
      this.tree.forEach((resource) => {
        if (resource.children) {
          resource.children.forEach((childResource, index) => {
            permissionData.forEach((data, dataIndex) => {
              if (
                childResource.resource.includes(data.resource) &&
                childResource.permission.includes(data.permission)
              ) {
                if (!menuToBeShown.includes(childResource.link)) {
                  menuToBeShown.push(childResource.link);
                }
              }
            });
          });
        } else {
          permissionData.forEach((data, dataIndex) => {
            if (
              resource.resource.includes(data.resource) &&
              resource.permission.includes(data.permission)
            ) {
              menuToBeShown.push(resource.link);
            }
          });
        }
      });

      this.tree.forEach((resource, resourceIndex) => {
        if (resource.children) {
          resource.children.forEach((childResource, index) => {
            if (!menuToBeShown.includes(childResource.link)) {
              resource.children.splice(index, 1);
            }
          });

          if (resource.children.length == 0) {
            this.tree.splice(resourceIndex, 1);
          }
        } else {
          if (!menuToBeShown.includes(resource.link)) {
            this.tree.splice(resourceIndex, 1);
          }
        }
      });

      return this.tree;
    },
    getThemeColor() {
      let themeColor = JSON.parse(window.getStorage(this.themeColorPrefix));
      let selectedThemeName = "";

      if (themeColor === null || themeColor == "themeOne") {
        selectedThemeName = "themeOne";
      } else {
        if (themeColor == "dark-origin") {
          selectedThemeName = "dark-origin";
        } else {
          selectedThemeName = "dark-third";
        }
      }

      let bodyTag = document.getElementsByTagName("BODY")[0];
      if (bodyTag.classList.length > 0) {
        bodyTag.classList.forEach((className) => {
          if (className == "themeOne") {
            bodyTag.classList.remove("themeOne");
          }
          if (className == "dark-origin") {
            bodyTag.classList.remove("dark-origin");
          }
          if (className == "dark-third") {
            bodyTag.classList.remove("dark-third");
          }
        });
      }
      bodyTag.classList.add(selectedThemeName);
    },
    setLayout() {
      if (
        this.$route.name == "login" ||
        this.$route.name == null ||
        this.$route.name == "termsPolicy"
      ) {
        this.hideComponent = true;
      } else {
        this.hideComponent = false;
      }
    },
    setPreference() {
      let bodyTag = document.getElementsByTagName("BODY")[0];
      bodyTag.className = "";
      if (window.getStorage("themeColor") != null) {
        let className = window.getStorage("themeColor");
        bodyTag.classList.add(className);
        if (className == "themeOne") {
          this.active1 = true;
        } else if (className == "dark-origin") {
          this.active2 = true;
        } else {
          this.active3 = true;
        }
      } else {
        bodyTag.classList.add("themeOne");
      }
    },
    termsPolicies(e) {
      if (e) {
        this.hideComponent = true;
      }
    },
    logout() {
      this.$router.push("/user/login");
    },
    setLogin(e) {
      if (e) {
        this.hideComponent = false;
        this.$root.isLoggedIn = true;
      }
    },
    login(e) {
      if (e) {
        this.hideComponent = true;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>

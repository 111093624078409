const storage_prefix = window.config.VUE_APP_STORAGE_PREFIX;

window.setStorage = (key, value) => {
    // localStorage.setItem(`${storage_prefix}${key}`, value);
    sessionStorage.setItem(`${storage_prefix}${key}`, value);
};

window.getStorage = key => {
    // return localStorage.getItem(`${storage_prefix}${key}`);
    return sessionStorage.getItem(`${storage_prefix}${key}`);
};

window.removeStorage = key => {
    // return localStorage.removeItem(`${storage_prefix}${key}`);
    return sessionStorage.removeItem(`${storage_prefix}${key}`);
};

window.setLocalStorage = (key, value) => {
    localStorage.setItem(`${storage_prefix}${key}`, value);
};

window.getLocalStorage = (key, value) => {
    return localStorage.getItem(`${storage_prefix}${key}`);
};
<template>
  <div
    class="global-message"
    :class="hideOverlay == true ? 'no-overlay' : ''"
    v-if="filteredMsg.length"
  >
    <div class="message-container">
      <template v-if="filteredMsg.length">
        <template v-for="(message, index) in filteredMsg">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated fadeInUp"
            leave-active-class="animated fadeOutUp"
            :key="index"
          >
            <div
              v-if="message.shown == true"
              class="alert"
              :class="[
                message.type == 'success'
                  ? 'alert-success'
                  : message.type == 'error'
                  ? 'alert-danger'
                  : 'aler-danger-top',
              ]"
              :key="index"
            >
              <span class="close-icon" @click="hideMessage(index)">
                <i class="material-icons">close</i>
              </span>
              <span v-html="message.message"></span>
              <template v-if="message.type != 'success' && message.type != 'errorOnTop'">
                <div class="btn-wrapper">
                  <template v-if="!checkReloadMessage">
                    <a
                      href=""
                      @click.prevent="hideMessage(index)"
                      :class="[
                        message.type == 'success' ? 'btn btn-success' : 'btn btn-danger',
                      ]"
                    >
                      <slot name="ok"> {{ btnText }} </slot>
                    </a>
                  </template>
                  <template v-else>
                    <a
                      href=""
                      @click.prevent="reloadPage()"
                      :class="[
                        message.type == 'success' ? 'btn btn-success' : 'btn btn-danger',
                      ]"
                    >
                      <slot name="ok"> {{ btnText }} </slot>
                    </a>
                  </template>
                </div>
              </template>
            </div>
          </transition>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApiMessage",
  data() {
    return {
      messages: window.messages,
      // selecteData: "",
      // cacelShow: false,
      myTime: null,
      arrayIndex: 0,
      btnText: "OK",
      checkReloadMessage: false,
      hideOverlay: false,
    };
  },
  watch: {
    messages(val) {
      if (val.length > 0) {
        if ("message" in val[0]) {
          var messageValue = val[0].message;
          if (typeof messageValue != "object") {
            if (
              messageValue.includes(
                "has been updated since you last loaded the page.  Please reload the page and then try updating again."
              )
            ) {
              this.btnText = "Reload";
              this.checkReloadMessage = true;
            }
          }
        }
      }

      if (val.length > 0) {
        if (this.arrayIndex > 0) {
          this.filterData(this.arrayIndex);
        } else {
          this.filterData();
        }
      }
    },
  },
  computed: {
    filteredMsg() {
      return this.messages;
    },
  },
  mounted() {},
  methods: {
    filterData(i = 0) {
      this.hideOverlay = false;
      if (i == this.messages.length) {
        window.messages = [];
        this.messages = window.messages;
        this.arrayIndex = 0;
      }
      if (i < this.messages.length) {
        if (i == 0) {
          this.messages[0].shown = true;
        }
        if (i > 0) {
          this.messages[0].shown = false;
          if (!this.messages[i].shown) {
            this.messages[i].shown = true;
          }
        }
        var lastindex = this.messages.length - 1;

        if (this.messages.length > 1) {
          this.messages.forEach((message, index) => {
            if (index < lastindex) {
              this.messages[index].shown = false;
            } else if (index == lastindex) {
              this.messages[index].shown = true;
            }
          });
        }
        if (
          this.messages[lastindex].type == "success" ||
          this.messages[lastindex].type == "errorOnTop"
        ) {
          this.hideOverlay = true;
          clearTimeout(this.myTime);
          this.myTime = setTimeout(() => {
            // if(this.arrayIndex == i){
            if (this.messages[lastindex] != undefined) {
              this.messages[lastindex].shown = false;
            }
            i++;
            this.arrayIndex = i;
            this.filterData(i);
            // }
          }, 3000);
        }
      }
    },
    hideMessage(index) {
      clearTimeout(this.myTime);
      this.messages[index].shown = false;
      if (index + 1 == this.messages.length) {
        window.messages = [];
        this.messages = window.messages;
        this.arrayIndex = 0;
      } else {
        let i = index + 1;
        this.arrayIndex = i;
        // this.filterData(i);
      }
    },
    reloadPage() {
      location.reload();
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/components/_apiMessage.scss";
.no-overlay.global-message {
  background: none !important;
  z-index: 999;
}
</style>

<template>
  <ul class="navbar-nav">
    <li
      class="nav-item"
      v-for="(node, index) in nodes"
      :key="index"
      @mouseenter="listMouseOver($event)"
      :class="{ 'has-submenu': node.children, 'slide-down': navIndex == index }"
    >
      <!-- <template v-if="showMenuItem"> -->
      <div class="nav-item-wrap" v-if="node.hide != true">
        <!-- <router-link :to="node.link" :class="node.class"> -->
        <a
          v-if="node.children"
          @click.capture="handleSubmenu($event)"
          :class="node.children ? 'has-childmenus' : 'node.class'"
        >
          <!-- <span @click.capture="(node.children?'handleOpenModal($event)':'')" :class="(node.children?'has-child':'')"> -->
          <router-link :to="node.link" :class="node.class">
            <span class="nav-item-content" @click="navIndex = index">
              <i class="material-icons" v-if="node.icon">{{ node.icon }}</i>
              <span class="nav-item-title"> {{ node.title }} </span>
              <span v-if="node.badge" class="badge badge-primary badge-pill">
                {{ node.badge }}
              </span>
            </span>
          </router-link>
        </a>
        <a v-else>
          <router-link :to="node.link" :class="node.class">
            <span class="nav-item-content" @click="navIndex = index">
              <i class="material-icons" v-if="node.icon">{{ node.icon }}</i>
              <span class="nav-item-title"> {{ node.title }} </span>
              <span v-if="node.badge" class="badge badge-primary badge-pill">
                {{ node.badge }}
              </span>
            </span>
          </router-link>
        </a>
        <span class="menu-arrow" @click="toggleSubMenu(index)" v-if="node.children">
          <i class="material-icons"> keyboard_arrow_right </i>
        </span>
      </div>
      <!-- <template v-show="node.children && node.children.length"> -->
      <transition name="slideDown" v-show="node.children && node.children.length">
        <NavbarList
          v-show="navIndex == index"
          class="submenu"
          :nodes="node.children"
        ></NavbarList>
      </transition>
      <!-- </template> -->
      <!-- </template> -->
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavbarList",
  props: {
    nodes: Array,
  },
  data() {
    return {
      navIndex: null,
      toggleMenu: false,
      subNav: 0,
      // showMenuItem: false,
      resource: "",
      permission: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        window.scrollTo(0, 0);
      }
    },
  },
  beforeMount() {},
  mounted() {
    this.removeHref();
  },
  created() {},
  methods: {
    getPermission() {
      var menuPermission = window.getStorage("menuStatus");
      this.showMenu = menuPermission;
    },
    removeHref() {
      let topMenu = document.querySelectorAll(".has-childmenus");
      for (let i = 0; i < topMenu.length; i++) {
        var url = topMenu[i].getElementsByTagName("a")[0];
        url.removeAttribute("href");
        url.querySelector(".nav-item-content").setAttribute("style", "cursor: pointer;");
      }
    },
    listMouseOver(event) {
      let top = event.target.getBoundingClientRect().top;
      let subMenu = event.target.querySelector(".submenu");
      subMenu.style.top = top + "px";
    },
    toggleSubMenu(index) {
      if (this.navIndex == index) {
        this.navIndex = null;
      } else {
        this.navIndex = index;
      }
    },
    handleSubmenu(e) {
      e.preventDefault();
    },
  },
};
</script>

<style>
.navbar-nav > li:hover > .nav-item-wrap > a > a {
  color: #d40000 !important;
}
.navbar-nav > li > .nav-item-wrap > a > a {
  color: rgba(87, 87, 87, 0.9) !important;
}
.navbar-nav > li > .nav-item-wrap > a > a.router-link-active,
.navbar-nav > li > .nav-item-wrap > a > a.router-link-active .nav-item-content > i {
  color: #d40000;
}
</style>

/* eslint-disable */
import Oidc, { UserManager } from 'oidc-client';
import 'babel-polyfill';
import TMUserManager from "@/library/users/manager";
import TaskManager from "@/library/tasks/manager";
import DealManager from "@/library/deals/manager";
var mgr = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({store: window.localStorage}),
  authority: window.app.vars.identityServer,
  client_id: window.app.vars.identityServerClientId,
  redirect_uri: window.location.origin + '/signin-callback',
  post_logout_redirect_uri: window.location.origin + '/signout-callback',
  response_type: 'code',
  scope: 'openid profile web_api offline_access',
  silent_redirect_uri: window.location.origin + '/silent-refresh.html',
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  accessTokenExpiringNotificationTime: 60,
  query_status_response_type: 'code',
});

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

mgr.events.addUserLoaded(function(user) {
});

mgr.events.addAccessTokenExpiring(function() {
});

mgr.events.addAccessTokenExpired(function() {
  var locale = sessionStorage.getItem('locale-setting');
  var timezone = sessionStorage.getItem('timezone');
  sessionStorage.clear();
  sessionStorage.setItem('locale-setting', locale);
  sessionStorage.setItem('timezone', timezone);
  mgr.signoutRedirect().then(function(resp) {
  }).catch(function(err) {
    console.log(err)
  })
});

mgr.events.addSilentRenewError(function() {
});

mgr.events.addUserSignedOut(function() {
  var locale = sessionStorage.getItem('locale-setting');
  var timezone = sessionStorage.getItem('timezone');
  sessionStorage.clear();
  sessionStorage.setItem('locale-setting', locale);
  sessionStorage.setItem('timezone', timezone);
  mgr.signoutRedirect().then(function(resp) {
  }).catch(function(err) {
    console.log(err)
  })
});

class SecurityService {
  constructor()
  {
  }
  // Renew the token manually
  renewToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then(function(user) {
        if (user == null) {
          self.signIn(null)
        } else {
          return resolve(user)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user who is logged in
  getUser() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn().then((user) => {
            mgr.storeUser(user);
            self.completeLogin();
          });
        } else {
          localStorage.setItem('user-token', user.access_token);
          return resolve(user)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    return mgr.signinRedirect().catch(function(err) {
      alert(err);
    });
  }

  async completeLogin() {
    return mgr.signinRedirectCallback().then((user) => {
      if (user.access_token !== '' && user.profile['name'] !== '') {
        window.setStorage('loggedInUser', user.profile['name']);
        window.setStorage('user-token', user.access_token);
        window.setStorage('user-profile', JSON.stringify(user.profile));
        mgr.storeUser(user);
        this.setStatusStorage();
        TaskManager.getPreferences(user.profile['id'], false, false, (response, error) => {
          if (response) {
            var gridPreferences = response.result;
            if (gridPreferences) {
              for (let [key, value] of Object.entries(gridPreferences)) {
                if (key == "locale-setting") {
                  window.setStorage(key, value);
                } else {
                  window.setStorage(key, JSON.stringify(value));
                }
                if (key == "themeColor") {
                  this.setThemeColor(value);
                }

                if (key == "timezone") {
                  window.setStorage(key, value);
                }

                this.setLocaleSettings();
              }
            }
          }

          if (response.isError) {
            this.setLocaleSettings();
          }
        });
        return user;
        // TMUserManager.getUserPermissionAcl(user.profile.id, (response, error) => {
        // });
      }
      else {
        this.signOut();
      }
    });
  }

  async setStatusStorage() {
    let allStatus = [];
    let taskviewerClosedStatus = [];

    const taskviewerStatus = await this.getTaskviewerStatus();
    const myTasksStatus = await this.getMyTasksStatus();
    const myApprovalsStatus = await this.getMyApprovalsStatus();
    const vendors = await this.getVendors();
    const referenceTypes = await this.getReferenceTypes();
    const taskTypes = await this.getTaskTypesData();
    const taskTags = await this.getTaskTags();

    taskviewerStatus.forEach((item) => {
      if (!allStatus.includes(item.name)) {
        allStatus.push(item.name);
      }

      if (item.isClosed == true) {
        if (!taskviewerClosedStatus.includes(item.name)) {
          taskviewerClosedStatus.push(item.name);
        }
      }
    });

    this.allTasksStatus = {
      allStatus: allStatus,
      taskviewerClosedStatus: taskviewerClosedStatus,
      myTaskStatus: myTasksStatus,
      myApprovalsStatus: myApprovalsStatus,
    };

    // localStorage.clear();
    window.setLocalStorage("status", JSON.stringify(this.allTasksStatus));
    window.setLocalStorage("vendors", JSON.stringify(vendors));
    window.setLocalStorage("reference_types", JSON.stringify(referenceTypes));
    window.setLocalStorage("task_types", JSON.stringify(taskTypes));
    window.setLocalStorage("task_tags", JSON.stringify(taskTags));
  }

  getTaskTypesData() {
    return new Promise((resolve, reject) => {
      let taskTypeDatas = [];
      TaskManager.getTaskTypes((response, error) => {
        var nameSentencase = "";
        var nameIdValue = "";
        if (response) {
          response.result.forEach((item, index) => {
            nameSentencase = item.name.replace(/([A-Z])/g, " $1").trim();
            nameIdValue = item.name.replace(/^.{1}/g, item.name[0].toLowerCase());
            taskTypeDatas.push({
              id: nameIdValue,
              text: nameSentencase,
              taskTypeId: item.id,
            });
          });
          resolve(taskTypeDatas);
        }
      });
    });
  }
  getTaskTags() {
    return new Promise((resolve, reject) => {
      TaskManager.getTaskTags(
        `?Page.PageNumber=1&Page.PageSize=50`,
        [],
        (response, error) => {
          if (response.isError == true) {
            resolve([]);
          } else {
            let displayInUIColumns = [];
            response.result.data.forEach((val) => {
              val.taskTags.forEach((tag) => {
                if (tag.displayInUI == true && !displayInUIColumns.includes(tag.key)) {
                  displayInUIColumns.push(tag.key);
                }
              });
            });

            resolve(displayInUIColumns);
          }
        }
      );
    });
  }
  getVendors() {
    return new Promise((resolve, reject) => {
      let vendorsList = [];
      DealManager.getVendors((response, error) => {
        if (response) {
          response.result.forEach((item) => {
            vendorsList.push(item);
          });
          resolve(vendorsList);
        }
      });
    });
  }
  getReferenceTypes() {
    return new Promise((resolve, reject) => {
      let referenceTypesList = [];
      TaskManager.getReferenceTypes((response, error) => {
        if (response) {
          response.result.forEach((item) => {
            if (item.isActive == true) {
              referenceTypesList.push(item);
            }
          });
          resolve(referenceTypesList);
        }
      });
    });
  }

  getTaskviewerStatus() {
    return new Promise((resolve, reject) => {
      TaskManager.getStatuses((response, error) => {
        if (response) {
          resolve(response.result);
        }
      });
    });
  }
  getMyTasksStatus() {
    return new Promise((resolve, reject) => {
      let taskGroup = "MyTasks";
      let myTasksStatus = [];
      TaskManager.getAllTaskStatuses(taskGroup, (response, error) => {
        if (response) {
          response.result.forEach((item) => {
            if (!myTasksStatus.includes(item.startingStatus.name)) {
              myTasksStatus.push(item.startingStatus.name);
            }
          });
          resolve(myTasksStatus);
        }
      });
    });
  }
  getMyApprovalsStatus() {
    return new Promise((resolve, reject) => {
      let taskGroup = "MyApprovals";
      let myApprovalsStatus = [];
      TaskManager.getAllTaskStatuses(taskGroup, (response, error) => {
        if (response) {
          response.result.forEach((item) => {
            if (!myApprovalsStatus.includes(item.startingStatus.name)) {
              myApprovalsStatus.push(item.startingStatus.name);
            }
          });
          resolve(myApprovalsStatus);
        }
      });
    });
  }

  setThemeColor(value) {
    let bodyTag = document.getElementsByTagName("BODY")[0];
    if (bodyTag.classList.length > 0) {
      bodyTag.classList.forEach((className) => {
        if (className == "themeOne") {
          bodyTag.classList.remove("themeOne");
        }
        if (className == "dark-origin") {
          bodyTag.classList.remove("dark-origin");
        }
        if (className == "dark-third") {
          bodyTag.classList.remove("dark-third");
        }
      });
    }
    bodyTag.classList.add(value);
  }

  setLocaleSettings() {
    if (window.getStorage("locale-setting") === null) {
      window.setStorage("locale-setting", "en-GB");
    }

    if (window.getStorage("timezone") === null) {
      window.setStorage("timezone", "Europe/London");
    }
  }

  async completeLogout() {
    var locale = sessionStorage.getItem('locale-setting');
    var timezone = sessionStorage.getItem('timezone');
    sessionStorage.clear();
    sessionStorage.setItem('locale-setting', locale);
    sessionStorage.setItem('timezone', timezone);
    return mgr.signoutRedirectCallback();
  }

  // Redirect of the current window to the end session endpoint
  async signOut() {
    mgr.signoutRedirect();
  }

  // Get the profile of the user logged in
  getProfile() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.profile)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the token id
  getIdToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.id_token)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the session state
  getSessionState() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.session_state)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the access token of the logged in user
  getAcessToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.access_token)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Takes the scopes of the logged in user
  getScopes() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.scopes)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user roles logged in
  getRole() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function(user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.profile.role)
        }
      }).catch(function(err) {
        console.log(err)
        return reject(err)
      });
    })
  }
}
export default new SecurityService();
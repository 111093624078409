

class Basemanager {
	get (callback) {
		this.model.get(callback)
	}

	post (data, callback) {
		this.model.post(data, callback)
	}

	patch (id, data, callback) {
		this.model.patch(id, data, callback)
	}

	delete (id, callback) {
		this.model.delete(id, callback)
	}
}

export default Basemanager
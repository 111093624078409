export const documentsRoutes = [
    {
        path:"/documents",
        name: 'Documents', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "Documents.Overview"
        },
        children: [
            {
                path:'overview',
                name: 'Documents.Overview',
                component: () => import('./Overview.vue'),
            },
            {
                path:'setting',
                name: 'Documents.Settings',
                component: () => import('./Settings.vue'),
            },
            {
                path:'library',
                name: 'Documents.Library',
                component: () => import('./Library.vue'),
            }
        ]
    }, 
]
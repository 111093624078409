import _ from "lodash"
class BaseService{
    constructor(service_name){
        this.service_name = service_name;
    }

    getServiceUrl(endpoint_name, method) {
        if(!this.service_config) this.service_config = window.app_settings['services'][this.service_name];
        let endpointConfig = this.service_config["endpoints"][endpoint_name]
        let url = ""
        let endpoint = ""
        let dataSource = 'WEB_SERVICE'
        if(typeof endpointConfig === 'object' && endpointConfig[method] != null){
            endpoint = endpointConfig[method]            
        }else if(typeof endpointConfig === 'string' || endpointConfig instanceof String){
            endpoint = endpointConfig
        }
        
        if (endpoint.endsWith(".json"))
        {
            dataSource = "JSON_FILE"
            url = process.env.VUE_APP_BASE_URL + endpoint
            method = "get"
        }
        else{
            url = this.service_config["base_url"] + endpoint
        }

        return url
    }

    fetch(endpoint_name, callback, options = { method: 'get', data: null, params: null, messages: null }){
        if(!this.service_config) this.service_config = window.app_settings['services'][this.service_name];
        let method = options.method || 'get';
        let endpointConfig = this.service_config["endpoints"][endpoint_name];
        let url = "";
        let endpoint = "";
        let dataSource = 'WEB_SERVICE';
        if(typeof endpointConfig === 'object' && endpointConfig[method] != null){
            endpoint = endpointConfig[method];            
        }else if(typeof endpointConfig === 'string' || endpointConfig instanceof String){
            endpoint = endpointConfig;
        }
        
        if (endpoint.endsWith(".json"))
        {
            dataSource = "JSON_FILE";
            url = window.VUE_APP_BASE_URL + endpoint;
            method = "get";
        }
        else{
            url = this.service_config["base_url"] + endpoint;
        }

        if(url != ""){
            let messages = options.messages || {};          
            axios({
                method: method,
                url: url,
                ...(method == 'get' && options.data != null && { params: options.data}),
                ...(method != 'get' && options.data != null && { data: options.data}),
                ...(method != 'get' && options.params != null && { params: options.params })
            }).then(response => {
                if(messages.success != null) {
                    window.messages.push({type: 'success', shown:false, message: messages.success == '' ? response.data.message : messages.success});
                }
                if(options.data !== null && dataSource == 'JSON_FILE'){
                    response.data.result = _.filter(response.data.result, options.data);
                }
                callback(response.data);
            }).catch(error => {
                if(messages.error != null) {
                  console.log('error: ', error);
                  window.messages.push({type: 'error', shown:false, message: messages.error == '' ? error.response.data.responseException.exceptionMessage : messages.error})
                }
                callback(null, error);
            })
        }else{
            callback(null, "Invalid Config Sent");
        }
        
    }
    
}

export default BaseService
export const bankAccountRoutes = [
    {
        path:"/bank_account",
        name: 'BankAccount', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "BankAccount.Overview"
        },
        children: [
            {
                path:'overview',
                name: 'BankAccount.Overview',
                component: () => import('./Overview.vue'),
            },
            {
                path:'add_bank_account',
                name: 'BankAccount.AddBankAccount',
                component: () => import('./AddBankAccount.vue'),
            }
        ]
    },
]
import BaseModel from '@/library/system/model'
import BaseService from '../system/service'
class Model extends BaseModel {
    constructor () {
        super()
        this.task_service = new BaseService('task_service')
    }


    getDefaultTasks (templateId, callback) {
        this.url = "http://credithub.chukhiro.com:5007/api/TaskTemplates/DefaultTasks"
		let url = `${this.url}/${templateId}`
		axios.get(url).then(response => {
			callback(response.data)
		}).catch(error => {
			callback(error.response.data)
		})
    }
    

    get_scheduled_tasks (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.scheduled
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }

    /**
     * Returns task templates making api call.
     * @param {*} callback 
     */
    get_task_templates(callback){
        this.task_service.fetch('task_templates',callback)
    }


    get_task_list (callback) {
        return this.task_service.fetch('taskList',callback)
    }

    get_reference_types (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.referenceTypes
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    get_primaries (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.primaries
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }

    // get_dummy_scheduled_tasks (callback) {
    //     let response = {
    //         "message": "Request successful.",
    //         "isError": false,
    //         "result": [
    //            {
    //             "source" : "",
    //             "taskname" : "",
    //             "taskDescription" : "",
    //             "primary" : "",
    //             "secondary" : "",
    //             "approvalRequired" : "",
    //             "approverRole" : "",
    //             "defaultDueDate" : "",
    //             "defaultAlertDate" : ""
    //            },
          
    //       ]
          
    //       }
    //     callback(response)
    // }

    get_generated_tasks (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.existing_scheduled
        axios.get(url).then(response => {
            callback(response.data)
            console.log(response.data)
        })
    }

    get_secondaries (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.secondaries
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    get_approver_roles (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.approverRoles
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    get_roll_conventions (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.rollConventions
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    getTemplatesList (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.templateManager
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    get_my_approvals (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.taskWorkbench
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }

    get_roles_list (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.taskRolesManager
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
    get_refobject_list (callback) {
        let url = window.app_settings.services.task_service.base_url+window.app_settings.services.task_service.endpoints.refObjectRoles
        axios.get(url).then(response => {
            callback(response.data)
        }).catch(error => {
            callback(null, error.response.data)
        })
    }
}

export default Model
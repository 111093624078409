const state = {
    dataGrid: {
        colFilter:null,
        colFilter2:null,
        colFilter3:null,
        colSort:[],
        colSort2:[],
        colSort3:[],
        gridRowIndex1: 0,
        gridRowIndex2: 0,
        gridRowIndex3: 0
    }
};

const getters = {
  getColFilter: state => {
    return state.dataGrid.colFilter;
  },
  getColSort: state => {
    return state.dataGrid.colSort;
  },
  getColFilter2: state => {
    return state.dataGrid.colFilter2;
  },
  getColSort2: state => {
    return state.dataGrid.colSort2;
  },
  getColFilter3: state => {
    return state.dataGrid.colFilter3;
  },
  getColSort3: state => {
    return state.dataGrid.colSort3;
  },
  getGridRowIndex1: state => {
    return state.dataGrid.gridRowIndex1;
  },
  getGridRowIndex2: state => {
    return state.dataGrid.gridRowIndex2;
  },
  getGridRowIndex3: state => {
    return state.dataGrid.gridRowIndex3;
  }
};

const mutations = {
  setColFilter: (state, payload) => {
    state.dataGrid.colFilter = payload;
  },
  setColSort: (state, payload) => {
    state.dataGrid.colSort = payload;
  },
  setColFilter2: (state, payload) => {
    state.dataGrid.colFilter2 = payload;
  },
  setColSort2: (state, payload) => {
    state.dataGrid.colSort2 = payload;
  },
  setColFilter3: (state, payload) => {
    state.dataGrid.colFilter3 = payload;
  },
  setColSort3: (state, payload) => {
    state.dataGrid.colSort3 = payload;
  },
  setGridRowIndex1: (state, payload) => {
    state.dataGrid.gridRowIndex1 = payload;
  },
  setGridRowIndex2: (state, payload) => {
    state.dataGrid.gridRowIndex2 = payload;
  },
  setGridRowIndex3: (state, payload) => {
    state.dataGrid.gridRowIndex3 = payload;
  }
};

const actions = {
  setColFilter: ({ commit }, payload) => {
    commit('setColFilter', payload);
  },
  setColFilter2: ({ commit }, payload) => {
    commit('setColFilter2', payload);
  },
  setColFilter3: ({ commit }, payload) => {
    commit('setColFilter3', payload);
  }
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
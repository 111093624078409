<template>
  <div>
    <h2>Logging In...</h2>
  </div>
</template>

<script>
/* eslint-disable */
import "babel-polyfill";
import SecurityService from "@/services/SecurityService";
import UserManager from "@/library/users/manager";
export default {
  name: "SignInRedirect",
  data() {
    return {};
  },
  beforeMount() {
    SecurityService.completeLogin().then((user) => {
      if (user.access_token !== "" && user.profile["name"] !== "") {
        UserManager.getUserPermissionAcl(user.profile.id, (response, error) => {
          if (response.emptyResources) {
            this.loading = false;
            this.showError = true;
            this.errorMessage = "No Resources assigned to the user's group";
            return;
          } else if (response.emptyACLData) {
            this.loading = false;
            this.showError = true;
            this.errorMessage = "No ACL Data found for the user";
            return;
          }
          window.setStorage("user-token", user.access_token);
          window.setStorage("user-profile", JSON.stringify(user.profile));
          this.$root.signedIn = true;
          this.$root.$emit('setLogin');
          this.$router.push("/TaskManager/Tasks");
        });
      } else {
        SecurityService.signOut();
      }
    });
  },
  methods: {
    isMountStreetUser(email) {
      let domain = email.split("@");
      return domain[1] == "mountstreet.com" || domain[1] == "mountstreetllp.com";
    },
  },
};
</script>

export const borrowersRoutes = [
    {
        path:"/borrowers",
        name: 'Borrowers', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "Borrowers.Overview"
        },
        children: [
            {
                path:'overview',
                name: 'Borrowers.Overview',
                component: () => import('./Overview.vue'),
            },
            {
                path:'add_borrower',
                name: 'Borrowers.AddBorrower',
                component: () => import('./AddBorrower.vue'),
            }
        ]
    },
]
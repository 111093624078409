import axios from "axios";
class BaseModel {
	get (callback) {        
		axios.get(this.url).then(response => {
			callback(response.data)
		}).catch(error => {
			callback(null, error.response.data)
		})
	}

	post (data, callback) {
		axios.post(this.url, data).then(response => {
			callback(response.data)
		}).catch(error => {
			callback(null, error.response.data)
		})
	}

	patch (id, data, callback) {
		let url = `${this.url}/${id}`
		axios.patch(url, data).then(response => {
			callback(response.data)
		}).catch(error => {
			callback(null, error.response.data)
		})
	}

	delete (id, callback) {
		let url = `${this.url}/${id}`
		axios.delete(url).then(response => {
			callback(response.data)
		}).catch(error => {
			callback(null, error.response.data)
		})
	}
}

export default BaseModel

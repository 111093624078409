const state = {
    dataGrid: {
        colFilter: null,
        colSort: [],
        // colFilter2: null,
        // colSort2: [],
        taskColFilter: null,
        taskColSort: [],
        rowContentData: null,
        taskPageNumber: null,
        taskPageSize: null
    },
};

const getters = {
    getColFilter: state => {
        return state.dataGrid.colFilter;
    },
    getColSort: state => {
        return state.dataGrid.colSort;
    },
    getTaskColFilter: state => {
        return state.dataGrid.taskColFilter;
    },
    getTaskColSort: state => {
        return state.dataGrid.taskColSort;
    },
    getRowContentData: state => {
        return state.dataGrid.rowContentData;
    },
    getTaskPageNumber: state => {
        return state.dataGrid.taskPageNumber;
    },
    getTaskPageSize: state => {
        return state.dataGrid.taskPageSize;
    },
};

const mutations = {
    setColFilter: (state, payload) => {
        state.dataGrid.colFilter = payload;
    },
    setColSort: (state, payload) => {
        state.dataGrid.colSort = payload;
    },
    setTaskColFilter: (state, payload) => {
        state.dataGrid.taskColFilter = payload;
    },
    setTaskColSort: (state, payload) => {
        state.dataGrid.taskColSort = payload;
    },
    setRowContentData: (state, payload) => {
        state.dataGrid.rowContentData = payload;
    },
    setTaskPageNumber: (state, payload) => {
        state.dataGrid.taskPageNumber = payload;
    },
    setTaskPageSize: (state, payload) => {
        state.dataGrid.taskPageSize = payload;
    },
};

const actions = {
    setColFilter: ({ commit }, payload) => {
        commit('setColFilter', payload);
    },
    // setColFilter2: ({ commit }, payload) => {
    //     commit('setColFilter2', payload);
    // }
    setTaskColFilter: ({ commit }, payload) => {
        commit('setTaskColFilter', payload);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
export const loanRoutes = [
    {
        path:"/loan",
        name: 'Loan', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "Loan.Deal"
        },
        children: [
            {
                path:'overview',
                name: 'Loan.Overview',
                component: () => import('./Overview.vue'),
                meta: {resource: "DealManagement"}
            },
            {
                path:'deals',
                name: 'Loan.Deal',
                component: () => import('./Deal.vue'),
                meta: {resource: ["DealManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']}
            },
            {
                path:'DealDetails/:slug',
                name: 'Loan.DealDetails',
                component: () => import('./DealDetails.vue'),
                meta: {resource: ["DealManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']}
            },
            {
                path:'advance_search',
                name: 'Loan.DealsAdvancedSearch',
                component: () => import('./DealsAdvancedSearch.vue'),
                meta: {resource: "DealManagement"}
            },
            {
                path:'summary/:slug',
                name: 'Loan.DealSummary',
                component: () => import('./DealSummary.vue'),
                meta: {resource: "DealManagement"}
            },
            {
                path:'summary/facility/:slug',
                name: 'Loan.DealSummaryFacility',
                component: () => import('./DealSummaryFacility.vue'),
                meta: {resource: "DealManagement"}
            }
        ]
    },
    {
        path:"/deals",
        name: 'Deals', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        redirect: {
            name: "Deal.BulkUpdate"
        },
        children: [
            {
                path:'bulkUpdate',                    
                name: 'Deal.BulkUpdate',
                component: () => import('./BulkUpdate.vue'),
                meta: {resource: ["DealManagement", "All"], permission: ['ReadWrite']}
            }
        ]
    }
]
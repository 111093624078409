export const staticDataRoutes = [
    {
        path:"/staticData",
        name: 'StaticData', 
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path:'countries',
                name: 'StaticData.Countries',
                component: () => import('./Countries.vue'),
                meta: {requiresAuth: true, resource: ["BusinessCalendarManagement", "All"]}
            },
            {
                path:'currencies',
                name: 'StaticData.Currencies',
                component: () => import('./Currencies.vue'),
                meta: {requiresAuth: true, resource: ["BusinessCalendarManagement", "All"]}
            },
            {
                path:'holidayCalendar',
                name: 'StaticData.HolidayCalendar',
                component: () => import('./HolidayCalendar.vue'),
                meta: {requiresAuth: true, resource: ["BusinessCalendarManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']}
            },
            {
                path:'nonWorkingDays/:slug',
                name: 'StaticData.NonWorkingDays',
                component: () => import('./NonWorkingDays.vue'),
                meta: {requiresAuth: true, resource: ["BusinessCalendarManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
                props: true
            },
        ]
    }, 
]
export const taskRoutes = [
    {
        path:"/taskManager",
        name: 'Task Manager',
        component: () => import('./Index.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path:'taskConfiguration',
                name: 'TaskManager.Task',
                component: () => import('./Task.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite']},
            },
            // {
            //     path:'workbench',
            //     name: 'TaskManager.Workbench',
            //     component: () => import('./Workbench.vue'),
            //     meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite']},
            // },
            {
                path:'generator',
                name: 'TaskManager.Generator',
                component: () => import('./Generator.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite'], },
            },
            {
                path:'templateManager',
                name: 'TaskManager.TemplateManager',
                component: () => import('./TemplateManager.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite']},
            },
            // {
            //     path:'taskpanel-approval/:slug',
            //     name: 'TaskManager.TaskManagerTaskPanel',
            //     component: () => import('./TaskPanel.vue'),
            //     meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly']},
            //     props: true
            // },
            // {
            //     path:'taskpanel-myTask/:slug',
            //     name: 'TaskManager.TaskManagerTaskPanelTask',
            //     component: () => import('./TaskPanel.vue'),
            //     meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly']},
            //     props: true
            // },
            {
                path:'TaskDetail/:slug',
                name: 'TaskManager.TaskManagerTaskPanel',
                component: () => import('./TaskDetail.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly']},
                props: true
            },
            {
                path:'TaskDetail/:slug',
                name: 'TaskManager.TaskManagerTaskPanelTask',
                component: () => import('./TaskDetail.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
                props: true
            },
            {
                path:'TaskDetail/:slug',
                name: 'TaskManager.TaskManagerTaskPanelServiceRequest',
                component: () => import('./TaskDetail.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
                props: true
            },
            {
                // path:'TaskViewer',
                // name: 'TaskManager.TaskViewer',
                path: 'Tasks',
                name: 'TaskManager.Tasks',
                component: () => import('./Tasks.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
            },
            // {
            //     path:'taskViewer/taskPanel/:slug',
            //     name: 'TaskManager.TaskViewerTaskPanel',
            //     component: () => import('./TaskViewerTaskPanel.vue'),
            //     meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly']},
            //     props: true
            // },
            {
                path:'TaskDetail/:slug',
                name: 'TaskManager.TaskViewerTaskPanel',
                component: () => import('./TaskDetail.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
                props: true
            },
            {
              path: 'TaskDetail2/:slug',
              name: 'TaskManager.TaskViewerTaskPanel2',
              component: () => import('./TaskDetail2.vue'),
              meta: { requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver'] },
              props: true
            },
            {
                path: 'Tags',
                name: 'TaskManager.Tags',
                component: () => import('./Tags.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
            },
            {
                path: 'Workflow',
                name: 'TaskManager.Workflow',
                component: () => import('./Workflow.vue'),
                meta: {requiresAuth: true, resource: ["TaskManagement", "All"], permission: ['ReadWrite', 'ReadOnly', 'Approver']},
            }
        ]
    },
]